.grid {
    display: grid;
    grid-template-columns: 1fr 40px;
    column-gap: var(--spacing-s);
    margin-bottom: 3px;
    align-items: center;
  }
  
  .link {
    all: inherit;
    color: var(--base-body-color);
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .icon {
    margin-left: 5px;
  }
  
  .copy {
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    color: #666;
  }
  
  .copy:hover {
    color: var(--base-body-color);
  }
  
  .group {
    margin-bottom: var(--spacing-m);
  }
  
  .group:last-child {
    margin-bottom: 0;
  }
  
  .groupName {
    text-transform: uppercase;
    color: var(--light-body-color);
    font-weight: bold;
    font-size: var(--font-size-xs);
    margin-bottom: var(--spacing-s);
  }

  .button {
    padding-top: 10px;
    display: grid;
  }