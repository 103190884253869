.fieldGroup {
  min-width: 600px;
}

.productSelector {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.productSelectorTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.productList {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 300px;
  overflow-y: scroll;
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}

.productName {
  flex-grow: 1;
  padding: 0 20px;
}
