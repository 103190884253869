/* Header section style */
.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.heading {
  margin: 20px 0 0 0;
}

.pageDescription {
  margin-bottom: 20px;
}

/* Container for left and right sections */
.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}
